<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="4">
              <CSelect
                placeholder="Search a company"
                :options="companiesOptions"
                :value.sync="searchData.companyId"
              />
            </CCol>
            <CCol col="12" sm="4">
              <CInput
                :placeholder="$t('search_a') + $t('email')"
                v-model="searchData.email"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CSelect
                placeholder="Search a enable"
                :options="enabledOptions"
                :value.sync="searchData.enabled"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getUsers()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="createUser()">{{ $t('create_user') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="createdAt" slot-scope="props">
              {{ $moment(props.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="editUser(props.row)">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CModal
        :title="$t('create_user')"
        color="info"
        :show.sync="createModal"
      >
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('company')"
              :placeholder="$t('company')"
              :options="companiesOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.companyId"
              :isValid="valid_createData_companyId"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name')"
              :placeholder="$t('name')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="createData.name"
              :isValid="valid_createData_name"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('email')"
              :placeholder="$t('email')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="createData.email"
              :isValid="valid_createData_email"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('password')"
              :placeholder="$t('generate_password_msg')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="createData.password"
              :isValid="valid_createData_password"
            >
              <CButton color="info" size="sm" slot="append" @click="generatePassword()"><CIcon name="cil-sync" /></CButton>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('lang')"
              :placeholder="$t('lang')"
              :options="langsOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.locale"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :placeholder="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.enabled"
              :isValid="valid_createData_enabled"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('role_groups')"
              :placeholder="$t('role_groups')"
              :options="roleGroupsOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="createData.roleGroupId"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">
                {{ $t('roles') }}
              </label>
              <div class="col-sm-9">
                <multiselect
                  v-model="createData.userRolesSelect"
                  :placeholder="$t('roles')"
                  label="label"
                  track-by="value"
                  :options="rolesOptions"
                  :multiple="true"
                  :show-labels="false"
                  :close-on-select="false"
                  :max-height="1000"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CButton slot="footer" color="primary" block @click="storeUser()">{{ $t('save') }}</CButton>
      </CModal>

      <CModal
        :title="$t('edit_user')"
        color="warning"
        :show.sync="editModal"
      >
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('company')"
              :placeholder="$t('company')"
              :options="companiesOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.companyId"
              :isValid="valid_editData_companyId"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name')"
              :placeholder="$t('name')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="editData.name"
              :isValid="valid_editData_name"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('email')"
              :placeholder="$t('email')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="editData.email"
              :isValid="valid_editData_email"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('password')"
              :placeholder="$t('generate_password_msg')"
              :description="$t('leave_blank_if_not_modified')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="editData.password"
            >
              <CButton color="info" size="sm" slot="append" @click="generatePassword()"><CIcon name="cil-sync" /></CButton>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('lang')"
              :placeholder="$t('lang')"
              :options="langsOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.locale"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :placeholder="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.enabled"
              :isValid="valid_editData_enabled"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('role_groups')"
              :placeholder="$t('role_groups')"
              :options="roleGroupsOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="editData.roleGroupId"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">
                {{ $t('roles') }}
              </label>
              <div class="col-sm-9">
                <multiselect
                  v-model="editData.userRolesSelect"
                  :placeholder="$t('roles')"
                  label="label"
                  track-by="value"
                  :options="rolesOptions"
                  :multiple="true"
                  :show-labels="false"
                  :close-on-select="false"
                  :max-height="1000"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CButton slot="footer" color="primary" block @click="updateUser()">{{ $t('save') }}</CButton>
      </CModal>

    </CCol>
  </CRow>
</template>

<script>
import Multiselect from 'vue-multiselect'
import random from 'crypto-random-string'

export default {
  name: 'user',
  components: {
    Multiselect
  },
  data () {
    return {
      columns: [ 'companyName', 'name', 'email', 'enabled', 'createdAt', 'action' ],
      data: [],
      options: {
        headings: {
          companyName: this.$t('company'),
          name: this.$t('name'),
          email: this.$t('email'),
          enabled: this.$t('enable'),
          createdAt: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'companyName', 'name', 'email', 'createdAt', 'enabled' ],
        filterable: [ 'companyName', 'name', 'email', 'createdAt', 'enabled' ]
      },
      companiesOptions: [{ value: null, label: this.$t('please_select_a') + this.$t('company') + '...' }],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      langsOptions: [
        { value: 'tw', label: '繁體中文' },
        { value: 'en', label: 'English' }
      ],
      rolesOptions: [{ value: 'root', label: 'ROOT' }],
      roleGroupsOptions: [{ value: null, label: this.$t('please_select_a') + this.$t('role_groups') + '...' }],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { companyId: null, email: '', enabled: null },
      createData: {},
      createModal: false,
      editData: {},
      editModal: false,
    }
  },
  computed: {
    valid_createData_companyId () {
      return this.createData.companyId ? true : false
    },
    valid_createData_name () {
      return this.createData.name ? true : false
    },
    valid_createData_email () {
      return /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.createData.email) ? true : false
    },
    valid_createData_password () {
      return this.createData.password ? true : false
    },
    valid_createData_enabled () {
      return this.createData.enabled === 0 || this.createData.enabled === 1 ? true : false
    },
    valid_editData_companyId () {
      return this.editData.companyId ? true : false
    },
    valid_editData_name () {
      return this.editData.name ? true : false
    },
    valid_editData_email () {
      return /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.editData.email) ? true : false
    },
    valid_editData_enabled () {
      return this.editData.enabled === 0 || this.editData.enabled === 1 ? true : false
    },
    valid_storeUser () {
      return this.valid_createData_name && this.valid_createData_email && this.valid_createData_password && this.valid_createData_enabled
    },
    valid_updateUser () {
      return this.valid_editData_name && this.valid_editData_email && this.valid_editData_enabled
    }
  },
  mounted: function () {
    this.getCompanies()
    this.getRoles()
    if (this.$store.getters.root) {
      this.getRoleGroups()
    }
  },
  methods: {
    getCompanies () {
      this.$store.dispatch('getCompanies').then(res => {
        for (let i in res) {
          this.companiesOptions.push({ value: res[i].id, label: res[i].name })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    getUsers () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getUsers', this.searchData).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    createUser () {
      this.createData = { companyId: null, name: '', email: '', password: '', locale: 'tw', enabled: 1, roleGroupId: null, rolesSelect: [] }
      this.createModal = true
    },
    storeUser () {
      if (this.valid_storeUser) {
        const loader = this.$loading.show()
        this.createData.userRoles = this.$_.pluck(this.createData.userRolesSelect, 'value')
        this.$store.dispatch('storeUser', this.$_.clone(this.createData)).then(res => {
          this.getUsers()
          this.createModal = false
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    editUser (prop) {
      this.editData = { id: prop.id, companyId: prop.companyId, name: prop.name, email: prop.email, password: '', locale: prop.locale, enabled: prop.enabled, roleGroupId: prop.roleGroupId, userRolesSelect: this.$_.filter(this.rolesOptions, option => prop.userRoles.includes(option.value)) }
      this.editModal = true
    },
    updateUser () {
      if (this.valid_updateUser) {
        const loader = this.$loading.show()
        this.editData.userRoles = this.$_.pluck(this.editData.userRolesSelect, 'value')
        this.$store.dispatch('updateUser', this.$_.clone(this.editData)).then(res => {
          this.getUsers()
          this.editModal = false
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    editUserRoles (prop) {
      this.editRolesData = {
        auid: prop.auid,
        name: prop.name,
        roleGroupId: prop.roleGroupId,
        userRolesSelect: this.$_.filter(this.rolesOptions, function (option) {
          return prop.adminUserRoles.includes(option.value)
        })
      }
      this.editRolesModal = true
    },
    updateUserRoles () {
      const loader = this.$loading.show()
      this.editRolesData.adminUserRoles = this.$_.pluck(this.editRolesData.userRolesSelect, 'value')
      this.$store.dispatch('updateUserRoles', { auid: this.editRolesData.auid, roleGroupId: this.editRolesData.roleGroupId, adminUserRoles: this.editRolesData.adminUserRoles }).then(res => {
        this.getUsers()
        this.editRolesModal = false
        this.$swal('Success', this.$t('updated_successfully'), 'success')
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getRoles () {
      this.$store.dispatch('getRoles').then(res => {
        for (let i in res) {
          this.rolesOptions.push({ value: res[i].role, label: res[i].name })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    getRoleGroups () {
      this.$store.dispatch('getRoleGroups').then(res => {
        for (let i in res) {
          this.roleGroupsOptions.push({ value: res[i].id, label: res[i].name })
        }
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    generatePassword () {
      const password = random({ length: 6, type: 'numeric' })
      this.createData.password = password
      this.editData.password = password
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
